@import 'styles/breakpoints';
@import 'styles/fonts';
@import 'styles/mixins';

.root {
    --spacing: 2rem;

    position: relative;
    width: 100%;
    background-color: var(--colors-ccBlueMedium);
    color: var(--colors-blue);
}

.inner {
    position: relative;
    width: 100%;
    flex-direction: column;

    @include flex-center;
}

.column {
    position: relative;
    display: flex;
    width: 100%;
    max-height: 45rem;
}

.aspect {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
}

.aspectInner {
    width: 100%;
    height: 100%;
    flex-direction: column;

    @include spread;
    @include flex-center;
}

.header {
    display: flex;
    max-width: 30rem;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.eyebrow {
    margin-bottom: 0;
    font-family: $GTWalsheimUltraBold;
    font-size: 4rem;
    font-weight: bold;
    line-height: 85%;
    text-transform: uppercase;
}

.headline {
    @include block-text(var(--colors-cream));

    font-size: 6.8rem;
    font-weight: bold;
    line-height: 0.85;
}

.subheadline {
    margin: var(--spacing) 0;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include spread;
}

@include medium {
    .inner {
        height: 100%;
        flex-direction: row;
    }

    .column {
        max-height: none;

        &.right {
            order: 1;
        }

        &.left {
            order: -1;
        }
    }
}

@include large {
    .root {
        --spacing: 4.8rem;

        height: calc(100vh - var(--header-height));
        min-height: 60rem;
    }

    .column {
        position: relative;
        width: 50%;
        height: 100%;
    }

    .aspect {
        height: 100%;
        padding-bottom: 0;
    }

    .header {
        max-width: 45rem;
    }

    .eyebrow {
        font-size: 6rem;
    }

    .headline {
        font-size: 11rem;
        line-height: 90%;
    }

    .subheadline {
        max-width: 42.2rem;
    }
}
