.root {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 80rem;
    flex-direction: column;

    // cream cheese colors
    --colors-ccBlueLight: #d4eaed;
    --colors-ccBlueMedium: #71c5ea;
    --colors-ccBlue: #2495d9;
    --colors-ccOriginal: #84d5f7;
    --colors-ccSeriouslyStrawberry: #f7a9a6;
    --colors-ccVeryVeggie: #f8a166;
    --colors-ccJalapenoHoney: #f6c035;
    --colors-ccChiveOnion: #57b252;
}
