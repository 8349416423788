@import 'styles/breakpoints';
@import 'styles/mixins';

.slide {
    --tweet-width: 24rem;
    --quote-width: 23rem;
    --content-padding: 1.6rem 2rem;
    --social-border-radius: 1.4rem;

    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 2rem;
}

.inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    text-align: center;

    @include flex-center;
}

.eyebrow {
    margin-bottom: 1rem;
    text-transform: uppercase;
}

.headline {
    font-size: 6.8rem;

    @include block-text(var(--colors-cream));
}

.subHeadline {
    margin-bottom: 2rem;
    font-size: 4rem;
    text-transform: uppercase;
}

.description {
    max-width: 24rem;
}

.social {
    position: absolute;
    top: var(--pos-y);
    left: var(--pos-x);
    overflow: hidden;
    width: var(--tweet-width);
    border: 2px solid var(--colors-blue);
    border-radius: var(--social-border-radius);
    background-color: var(--colors-ccBlueLight);
    box-shadow: -10px 10px var(--colors-blue);
    color: var(--colors-blue);
    text-align: left;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--content-padding);
}

.copy {
    margin-bottom: 0.4rem;
}

.author {
    color: var(--colors-ccBlue);
    font-weight: bold;
}

@include smallOnly {
    .author {
        font-size: 1.2rem;
    }
}

@include mediumOnly {
    .author {
        font-size: 1.2rem;
    }
}

@include large {
    .slide {
        --tweet-width: 30.5rem;
        --social-border-radius: 3rem;
        --content-padding: 2rem;
    }

    .social {
        top: var(--desktop-pos-y);
        left: var(--desktop-pos-x);
        border-width: 4px;
    }
}

@include xLarge {
    .description {
        max-width: 40rem;
    }
}

@include xxLarge {
    .slide {
        --tweet-width: 44.5rem;
        --content-padding: 4rem;
    }

    .eyebrow {
        margin-bottom: 1.4rem;
        font-size: 2.4rem;
    }

    .headline {
        font-size: 14.8rem;
        line-height: 13.3rem;
    }

    .subHeadline {
        margin-bottom: 3rem;
        font-size: 9.8rem;
        line-height: 7.8rem;
    }

    .description {
        max-width: 50.5rem;
    }
}
