// Font variables
// -------------------------

$GTWalsheim: 'GT Walsheim Pro', sans-serif !default;
$GTWalsheimCond: 'GT Walsheim Pro Condensed', sans-serif !default;
$GTWalsheimUltraBold: 'GT Walsheim Pro Ultra', sans-serif !default;

$Parkinson: 'parkinson', serif !default;

$Register: 'Register', sans-serif !default;
// Font definition manifest
// -------------------------

// GT Walsheim Pro Light
// font-family: 'GT Walsheim Pro'; font-weight: 300; font-style: normal;

// GT Walsheim Pro Regular
// font-family: 'GT Walsheim Pro'; font-weight: normal; font-style: normal;

// GT Walsheim Pro Thin Oblique
// font-family: 'GT Walsheim Pro'; font-weight: 100; font-style: italic;

// GT Walsheim Pro Thin
// font-family: 'GT Walsheim Pro'; font-weight: 100; font-style: normal;

// GT Walsheim Pro Bold Oblique
// font-family: 'GT Walsheim Pro'; font-weight: bold; font-style: italic;

// GT Walsheim Pro Ultra Bold
// font-family: 'GT Walsheim Pro Ultra'; font-weight: 800; font-style: normal;

// GT Walsheim Pro Ultra Bold Oblique
// font-family: 'GT Walsheim Pro Ultra'; font-weight: 800; font-style: italic;

// GT Walsheim Pro Medium Oblique
// font-family: 'GT Walsheim Pro'; font-weight: 500; font-style: italic;

// GT Walsheim Pro Bold
// font-family: 'GT Walsheim Pro'; font-weight: bold; font-style: normal;

// GT Walsheim Pro Medium
// font-family: 'GT Walsheim Pro'; font-weight: 500; font-style: normal;

// GT Walsheim Pro Regular Oblique
// font-family: 'GT Walsheim Pro'; font-weight: normal; font-style: italic;

// GT Walsheim Pro Light Oblique
// font-family: 'GT Walsheim Pro'; font-weight: 300; font-style: italic;

// GT Walsheim Pro Ultra Light
// font-family: 'GT Walsheim Pro Ultra'; font-weight: 200; font-style: normal;

// GT Walsheim Pro Ultra Light Oblique
// font-family: 'GT Walsheim Pro Ultra'; font-weight: 200; font-style: italic;

// GT Walsheim Pro Black Oblique
// font-family: 'GT Walsheim Pro'; font-weight: 900; font-style: italic;

// GT Walsheim Pro Black
// font-family: 'GT Walsheim Pro'; font-weight: 900; font-style: normal;

// GT Walsheim Pro Condensed Ultra Bold
// font-family: 'GT Walsheim Pro Cond Ultra'; font-weight: 800; font-style: normal;

// GT Walsheim Pro Condensed Light
// font-family: 'GT Walsheim Pro Condensed'; font-weight: 300; font-style: normal;

// GT Walsheim Pro Condensed Bold Oblique
// font-family: 'GT Walsheim Pro Condensed'; font-weight: bold; font-style: italic;

// GT Walsheim Pro Condensed Light Oblique
// font-family: 'GT Walsheim Pro Condensed'; font-weight: 300; font-style: italic;

// GT Walsheim Pro Condensed Thin
// font-family: 'GT Walsheim Pro Condensed'; font-weight: 100; font-style: normal;

// GT Walsheim Pro Condensed Ultra Light Oblique
// font-family: 'GTWalsheimPro-CondensedULightIt'; font-weight: 200; font-style: italic;

// GT Walsheim Pro Condensed Medium
// font-family: 'GT Walsheim Pro Cond'; font-weight: 500; font-style: normal;

// GT Walsheim Pro Condensed Black Oblique
// font-family: 'GT Walsheim Pro Condensed'; font-weight: 900; font-style: italic;

// GT Walsheim Pro Condensed Regular
// font-family: 'GT Walsheim Pro Condensed'; font-weight: normal; font-style: normal;

// GT Walsheim Pro Condensed Thin Oblique
// font-family: 'GT Walsheim Pro Condensed'; font-weight: 100; font-style: italic;

// GT Walsheim Pro Condensed Bold
// font-family: 'GT Walsheim Pro Condensed'; font-weight: bold; font-style: normal;

// GT Walsheim Pro Condensed Regular Oblique
// font-family: 'GT Walsheim Pro Condensed'; font-weight: normal; font-style: italic;

// GT Walsheim Pro Condensed Ultra Light
// font-family: 'GTWalsheimPro-CondensedULight'; font-weight: 200; font-style: normal;

// GT Walsheim Pro Condensed Ultra Bold Oblique
// font-family: 'GT Walsheim Pro Cond Ultra'; font-weight: 800; font-style: italic;

// GT Walsheim Pro Condensed Medium Oblique
// font-family: 'GT Walsheim Pro Cond'; font-weight: 500; font-style: italic;

// GT Walsheim Pro Condensed Black
// font-family: 'GT Walsheim Pro Condensed'; font-weight: 900; font-style: normal;
