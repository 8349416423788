@import 'styles/breakpoints';

// Mixins
// -------------------------
@mixin spread {
    position: absolute;
    inset: 0;
}

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin bottom-divider {
    border-bottom: 0.1rem solid rgba(color('blue'), 0.2);
}

@mixin last-filler-item($columns) {
    @for $i from 0 through $columns - 1 {
        .listItem:last-child:nth-child(#{$columns}n - #{$i}) {
            grid-column: span #{$i + 1};
        }
    }
}

@mixin center-x {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
}

@mixin center-y {
    top: 50%;
    transform: translate3d(0, -50%, 0);
}

@mixin center-xy {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

@mixin carousel-gutters($offset) {
    &:first-of-type > * {
        padding-left: 0;
        margin-left: $offset;
    }

    &:last-of-type > * {
        padding-right: 0;
        margin-right: $offset;
    }
}

@mixin carousel-scrollbar-offset($start, $end) {
    ::-webkit-scrollbar-track {
        margin-inline: $start $end;
    }
}

@mixin block-text($color) {
    --block-outline: 0.03em;

    color: $color;
    line-height: 0.83;
    text-shadow: var(--colors-blue) -0.075em 0.07em;
    -webkit-text-stroke: var(--block-outline) var(--colors-blue);
    text-transform: uppercase;
}

@mixin ncd-circle-drop-shadow {
    border: 2px solid var(--colors-blue);
    box-shadow: -1px 1px 0 var(--colors-blue), -2px 2px 0 var(--colors-blue),
        -3px 3px 0 var(--colors-blue);
    color: var(--colors-blue);
}

@mixin slices-eyebrow {
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;

    @include large {
        font-size: 2rem;
        letter-spacing: 0.2rem;
    }
}

@mixin block-jams-cta {
    height: 4rem;
    padding: 0 2rem;
    border-radius: 10rem;

    span {
        font-size: 1.4rem;
    }

    @include large {
        height: 5rem;
        padding: 0 3rem;

        span {
            font-size: 2rem;
        }
    }
}

@mixin rounded-corners($breakpoint: small, $overflow: visible, $radius: 2rem) {
    @if $breakpoint == medium {
        @include medium {
            border-radius: $radius;
        }
    } @else if $breakpoint == large {
        @include large {
            border-radius: $radius;
        }
    } @else if $breakpoint == xLarge {
        @include xLarge {
            border-radius: $radius;
        }
    } @else {
        border-radius: $radius;
    }

    @if $overflow == hidden {
        overflow: hidden;
    }
}
