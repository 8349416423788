@import 'styles/breakpoints';
@import 'styles/mixins';

.root {
    --slide-offset: 2.4rem;
    --slide-spacing: 1rem;
    --root-padding: 4.8rem 0;
    --item-width: 85vw;

    overflow: hidden;
    width: 100%;
    padding: var(--root-padding);
    color: var(--colors-blue);
}

.carousel {
    @include carousel-scrollbar-offset(
        var(--slide-offset),
        var(--slide-offset)
    );
}

.slide {
    @include carousel-gutters(var(--slide-offset));
}

.item {
    position: relative;
    width: var(--item-width);
    height: 100%;
    margin: 0;
    background: transparent;
}

@include medium {
    .root {
        --item-width: calc(((100vw - ((var(--block-padding-side) * 2))) / 2));
    }
}

@include large {
    .root {
        --slide-offset: var(--block-padding-side);
        --item-width: 75vw;
    }
}

@include blockMaxWidthPlusPadding {
    .root {
        --slide-offset: calc((100vw - var(--block-max-width)) / 2);
    }
}
