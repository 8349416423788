$medium: 768px;
$large: 1024px;
$xLarge: 1280px;
$xxLarge: 1440px;
$xxxLarge: 1920px;
$blockMaxWidthPlusPadding: 1612.8px;

@mixin print {
    @media print {
        @content;
    }
}

@mixin medium {
    @media (min-width: $medium) {
        @content;
    }
}
@mixin large {
    @media (min-width: $large) {
        @content;
    }
}
@mixin xLarge {
    @media (min-width: $xLarge) {
        @content;
    }
}
@mixin xxLarge {
    @media (min-width: $xxLarge) {
        @content;
    }
}
@mixin xxxLarge {
    @media (min-width: $xxxLarge) {
        @content;
    }
}

@mixin smallOnly {
    @media (max-width: #{$medium - 1}) {
        @content;
    }
}
@mixin mediumOnly {
    @media (min-width: $medium) and (max-width: #{$large - 1}) {
        @content;
    }
}
@mixin largeOnly {
    @media (min-width: $large) and (max-width: #{$xLarge - 1}) {
        @content;
    }
}

@mixin portrait {
    @media (orientation: portrait) {
        @content;
    }
}

@mixin landscape {
    @media (orientation: landscape) {
        @content;
    }
}

@mixin touchscreens {
    @media (hover: none) and (pointer: coarse) {
        @content;
    }
}

@mixin blockMaxWidthPlusPadding {
    @media (min-width: $blockMaxWidthPlusPadding) {
        @content;
    }
}
